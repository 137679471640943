import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from 'styled-components';
import {AiFillLinkedin} from 'react-icons/ai';
import { Link } from "gatsby";
import {FadeIn} from '../animations/anim';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  outline: none;
  user-select: none;
  &:visited {
    color: white;
  }
`;


const Text = styled.p`
  line-height: 24px;
  text-align: left;
`;

const TD = styled.td`
  padding: 12px;
`


const Privacy = (props) => {

  return (
    <Layout location={props.location}>
      <SEO title="Privacy Policy" />
      <div style={{margin: 48}}>
        <h1 style={{marginTop: 128}}>
          Privacy Policy
        </h1>
        <p style={{fontSize: '16px', marginLeft: '0px', paddingBottom: '30px', textAlign: 'justify'}}>
                  This privacy policy explains how we collect and use your personal data and cookies. It also describes your rights towards us and how you can exercise them. We will only use the information you provide in accordance with this privacy policy and the General Data Protection Regulation (EU 2016/679).
                </p>
                <p style={{fontsize: '30px'}}>1 PERSONAL DATA THAT YOU GIVE US</p>
                <p style={{fontsize: 16, marginleft: 20, whitespace: 'pre-line', textalign: 'justify'}}>
                  You may choose to give us your personal data. This includes information submitted when you visit our website, use our Services or if you contact us (collectively referred to as “Services” in this privacy policy).
                  We will process the following categories of personal data that you give us:
                  1.1	<u>When you create a trial account:</u> name and company email address.
                  1.2	<u>When you create a user account:</u> name, company email address and phone number of the contact person as well as your company name, office address and VAT-number.
                  1.3	<u>When you subscribe to our newsletter:</u> name and email address.
                  1.4	<u>When you send us a job application:</u> Name, email address, records to evaluate your suitability for the position, your CV and cover letter, as well as other personal data as may be relevant for the specific application.
                  1.5	<u>If you contact us:</u> We may ask for additional personal data other than as informed above, in order to assist you.
                </p>
                <p style={{fontsize:' 30px'}}>2. PERSONAL DATA THAT WE COLLECT</p>
                <p style={{fontsize: '16px',  whitespace: 'pre-line', textalign: 'justify'}}>
                  We will process the following categories of personal data that we collect:
                  2.1	<u>To administer payments and invoice you:</u> name, email address and phone number of the contact person as well as your company name, office address and VAT-number.
                  2.2	<u>To manage the customer or supplier relationship:</u> name, email address and company name.
                  2.3	<u>By applying cookies when you visit our website:</u>
                </p>
                <p style={{fontSize: '16px', marginLeft: '40px', whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  (a)	We collect information about the device you use to access our Services, including the device identifier, geolocation and IP-address.
                  (b)	We collect information on how you use our website, such as information regarding how often you visit our website and which pages you visit on the website.
                  (c)	We use social media widgets to enable visitors to engage with content from our website on the social platforms Facebook, YouTube, Twitter, LinkedIn, Instagram and GitHub. To understand how and what information is collected and what cookies are set by these third parties when you click on the links of the respective social platform, please refer to the privacy policy of the appropriate social platform.
                  (d) We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices (in particular device's IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. For further details, please see Hotjar’s privacy policy by clicking on this <a href="https://www.hotjar.com/legal/policies/privacy">link</a>. You can opt-out to the creation of a user profile, Hotjar’s storing of data about your usage of our site and Hotjar’s use of tracking cookies on other websites by following this opt-out <a href="https://www.hotjar.com/legal/compliance/opt-out">link</a>.
                </p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  2.4	If you do not want to accept cookies, you may adjust your web browser settings either to not accept cookies or to indicate when a cookie is used. Please note that by disabling cookies, some features on our website might not work as intended.
                </p>
                <p style={{fontSize: '30px', marginTop: 30}}>3	HOW WE USE AND KEEP YOUR PERSONAL DATA</p>
                <p style={{fontSize: '16px', whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  3.1	We use your personal data to be able to provide our Services and fulfill our commitments towards you. We process personal data based on the following legal grounds.
                </p>
                <table align="center" style={{width: '100%', marginTop: '30px', background: '#242424'}}>
                  <tbody><tr>
                      <th>Purpose of the Processing</th>
                      <th>Personal Data Categories</th>
                      <th>Legal Basis for the Processing</th>
                      <th>Storage Period</th>
                    </tr>
                    <tr>
                      <TD>Manage your user account.</TD>
                      <TD>Name, email address and phone number of the contact person as well as your office address.</TD>
                      <TD>Fulfill our contractual obligations towards you.</TD>
                      <TD>As long as you are a customer, and one year after.</TD>
                    </tr>
                    <tr>
                      <TD>Provide support services.</TD>
                      <TD>Name, email address and phone number of the contact person.</TD>
                      <TD>Fulfill our contractual obligations towards you.</TD>
                      <TD>As long as necessary to provide the support, and one year after.</TD>
                    </tr>
                    <tr>
                      <TD>Send you our newsletter and provide updates and other information regarding our Services.</TD>
                      <TD>Name and email address.</TD>
                      <TD>Fulfill our contractual obligations towards you and to pursue legitimate interest.</TD>
                      <TD>As long as we send out such information, unless you unsubscribe.</TD>
                    </tr>
                    <tr>
                      <TD>If you contact us.</TD>
                      <TD>Name, email address and phone number of the contact person.</TD>
                      <TD>Fulfill our contractual obligations towards you and to pursue legitimate interest.</TD>
                      <TD>As long as is necessary to assist you.</TD>
                    </tr>
                    <tr>
                      <TD>Improve our website and understand how our website is used.</TD>
                      <TD>Cookies described in Section 2.3 above.</TD>
                      <TD>Pursue legitimate interest.</TD>
                      <TD>6 months after your visit.</TD>
                    </tr>
                    <tr>
                      <TD>Administer customer relationship, including order and payment processing.</TD>
                      <TD>Name, email address and phone number of the contact person.</TD>
                      <TD>Fulfill our contractual obligations towards you, and a legal obligation.</TD>
                      <TD>7 years after creation due to book keeping legislation.</TD>
                    </tr>
                    <tr>
                      <TD>Manage your job application or application to a specific job position.</TD>
                      <TD>Name, email address and phone number as well as other personal data provided by you.</TD>
                      <TD>Pursue legitimate interest, and consent if stored longer.</TD>
                      <TD>Until the position has been filled. Subject to your explicit consent, we may ask to store it for a longer period.</TD>
                    </tr>
                  </tbody></table>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  3.2	Your personal data will be deleted by us when the processing is no longer necessary for the purposes stated above, except if required by applicable laws. In such case, we keep the data only as long as necessary or mandated by law for such purpose, such as for book keeping purposes.
                </p>
                <p style={{fontSize: '30px'}}>4	SHARING OF PERSONAL DATA</p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  4.1	We share your personal data with the following subcontractors to provide our Services and perform our contractual obligations towards you:
                </p>
                <table align="center" style={{width: '100%', marginTop: '30px', backgroundColor: '#242424'}}>
                  <tbody><tr>
                      <th>Subcontractor name (service name)</th>
                      <th>Region for processing</th>
                      <th>Transfer mechanism</th>
                      <th>Services provided</th>
                    </tr>
                    <tr>
                      <TD>Amazon Web Services Inc. (AWS)</TD>
                      <TD>EU/EEA and the U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Hosting and storage services</TD>
                    </tr>
                    <tr>
                      <TD>Microsoft Corporation (Azure)</TD>
                      <TD>EU/EEA and the U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Hosting and storage services</TD>
                    </tr>
                    <tr>
                      <TD>Google, Inc. (Cloud Platform)</TD>
                      <TD>EU/EEA and the U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Hosting and storage services</TD>
                    </tr>
                    <tr>
                      <TD>The Rocket Science Group, LLC (Mailchimp)</TD>
                      <TD>U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Newsletter distribution</TD>
                    </tr>
                    <tr>
                      <TD>Wint Group AB (Wint)</TD>
                      <TD>Sweden</TD>
                      <TD>Not required</TD>
                      <TD>Accounting services</TD>
                    </tr>
                    <tr>
                      <TD>Pipedrive Inc. (Pipedrive)</TD>
                      <TD>U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Customer management</TD>
                    </tr>
                    <tr>
                      <TD>Google, Inc. (Gmail)</TD>
                      <TD>U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Email services</TD>
                    </tr>
                    <tr>
                      <TD>Google, Inc. (Google Analytics)</TD>
                      <TD>U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Cookies for statistics and product improvement</TD>
                    </tr>
                    <tr>
                      <TD>Hotjar Ltd. (Hotjar)</TD>
                      <TD>EU/EEA</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Cookies for statistics and product improvement</TD>
                    </tr>
                    <tr>
                      <TD>Mixpanel Inc. (Mixpanel)</TD>
                      <TD>U.S.</TD>
                      <TD>EU-U.S. Privacy Shield Framework</TD>
                      <TD>Cookies for statistics and product improvement</TD>
                    </tr>
                  </tbody></table>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  4.2	These third parties are limited by law or contract from using the personal data for purposes beyond those for which the personal data is shared. We take all reasonable legal, technical, and organizational measures to ensure that your personal data is treated securely and with an adequate level of protection when transferred to or shared with such selected third parties.
                  4.3	Some of the subcontractors we share your personal data with are located outside the EU/EEA (in a third country). Depending on which part of our Services you use, your personal data may be transferred to the United States. When doing so, we are committed to protect your data and comply with applicable data protection laws and will therefore put in place adequate safeguards to protect your personal data, such as the EU Commission’s Standard Contractual Clauses, or transfer the personal data to subcontractors certified in accordance with the EU-U.S. Privacy Shield Framework.
                  4.4	If we are required by law or you have agreed to it, we will disclose necessary personal data to authorities such as the police, tax agencies or other authorities. An example of legally required sharing is for the purposes of anti-money laundering and counter-terrorist financing.
                  4.5	In the event that we sell or buy any business or assets, we may disclose your personal data to the prospective seller or buyer of such business or assets. If we or substantially all of our assets are acquired by a third party, personal data about our customers may be disclosed and transferred.
                </p>
                <p style={{fontSize: '30px'}}>5	YOUR RIGHTS</p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  5.1	The right to a register excerpt. You have the right to request a transcript of your personal data that we store and process. Your request must be submitted in writing to us using the contact information below, including your signature.
                  5.2	The right to rectification. We want you to correct inaccurate or incomplete information about you, and ask you to keep your account updated at all times or contact us in this case.
                  5.3	Data portability. When it comes to personal data that you have provided to us, you have the right to request a transfer to another provider. Contact us for help with this.
                  5.4	The right to be forgotten. You have the right to object to our processing of your personal data. The consequence of this may be that we are no longer able to carry out the Services. Contact us and we'll see to what extent this is possible.
                  5.5	Marketing communications. You may at any time decline marketing communications from us. Let us know in that case.
                  5.6	Complaints. If you are displeased with our processing of personal data, you should contact us and let us know. You can also turn to the Swedish Data Protection Authority (Sw. Datainspektionen), Box 8114, 104 20 Stockholm, Sweden, phone number +46 8 657 61 00, email address datainspektionen@datainspektionen.se, or the equivalent authority in the EU-member state where you live, to file a complaint.
                </p>
                <p style={{fontSize: '30px'}}>6	SECURITY</p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  6.1	We are using adequate technical and organizational security measures to ensure that your personal data is not misused, lost or unlawfully accessed. We only give access to your personal data to those employees who require it to provide our services.
                </p>
                <p style={{fontSize: '30px'}}>7	UPDATES TO THIS POLICY</p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  7.1	We may occasionally update this privacy policy. If we make significant changes, we will notify you of the changes through our Services or through other means, such as email. To the extent permitted under applicable law, by using our Services after such notice, you accept the updates.
                  7.2	We encourage you to periodically review this privacy policy for the latest information on our privacy practices.
                </p>
                <p style={{fontSize: '30px'}}>8	CONTACT INFORMATION</p>
                <p style={{fontSize: '16px',  whiteSpace: 'pre-line', textAlign: 'justify'}}>
                  8.1	If you have any questions regarding our processing of your personal data, or any question, complaint or claim, please contact us at:
                  Greenlytics AB
                  Org. no. 559115-5170
                  Stortorget 18
                  111 29 Stockholm, Sweden
                  E-mail: support@rebase.energy
                  www.rebase.energy
                </p>
      </div>

    </Layout>
  );

}

export default Privacy;
